import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commonFetch } from "@utils";

export const fetchCampaigns = createAsyncThunk(
  "fetchCampaigns",
  async (args, thunkAPI) => {
    const url = `/api/v2/brands/${args.brandId}/campaigns`
    let data = await commonFetch(url, args, thunkAPI);
    return data;
  }
)

export const fetchCampaign = createAsyncThunk(
  "fetchCampaign",
  async (args, thunkAPI) => {
    const url = `/api/v2/campaigns/${args.campaignId}`
    let data = await commonFetch(url, args, thunkAPI);
    return data;
  }
)

const campaignSlice = createSlice({
  name: 'campaignSlice',
  initialState: {
    campaigns: [],
    currentCampaign: {},
    isFetching: false,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaigns.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.isFetching = false;
        state.campaigns = action.payload;
      })
      .addCase(fetchCampaign.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(fetchCampaign.fulfilled, (state, action) => {
        state.isFetching = false;
        state.currentCampaign = action.payload;
      })
  }
})

const { reducer } = campaignSlice;
export default reducer;