import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const campaignsApi = createApi({
  reducerPath: 'Campaigns',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v2' }),
  endpoints: (builder) => ({
    getCampaigns: builder.query({
      query: (brandId) => ({ url: `brands/${brandId}/campaigns` }),
    }),
  })
})

export const { useGetCampaignsQuery } = campaignsApi;