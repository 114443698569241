import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const bannerRotationsApi = createApi({
  reducerPath: 'BannerRotations',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v2' }),
  endpoints: (builder) => ({
    getFilteredBannerRotations: builder.query({
      query: ({width, height, company_id, brand_id, campaign_id}) => `filtered_banner_rotations?width=${width}&height=${height}&company_id=${company_id}${brand_id ? "&brand_id="+brand_id : ""}${campaign_id ? "&campaign_id="+campaign_id : ""}`
    }),
    getBannerRotationsByIds: builder.query({
      query: ({width, height, ids}) => `filtered_banner_rotations/new?width=${width}&height=${height}&ids=${ids.join(",")}`
    })
  })
})

export const { useGetFilteredBannerRotationsQuery, useGetBannerRotationsByIdsQuery } = bannerRotationsApi;;