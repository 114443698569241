import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { postHeaders } from "@utils";

export const campaignRotationsApi = createApi({
  reducerPath: "CampaignRotations",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/v2/campaign_rotations" }),
  endpoints: (builder) => ({
    getCampaignRotations: builder.query({
      query: () => "",
    }),
    getCampaignRotation: builder.query({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'CampaignRotations', id }],
    }),
    getAvailableSizes: builder.query({
      query: () => "/available_sizes",
    }),
    saveNewCampaignRotation: builder.mutation({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data,
        headers: postHeaders(),
      }),
    }),
    updateCampaignRotation: builder.mutation({
      query: (data) => ({
        url: `/${data.id}`,
        method: "PATCH",
        body: data,
        headers: postHeaders(),
      }),
    }),
    deleteCampaignRotation: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
        headers: postHeaders(),
      }),
      invalidatesTags: (result, error, id) => [{ type: 'CampaignRotations', id }],
    }),
    newCampaignRotationEntries: builder.query({
      query: (data) => {
        const ids = data.ids.join(",");
        return {
          url: `/${data.campaign_rotation_id}/campaign_rotation_entries/new`,
          params: { ids },
        }
      },
      invalidatesTags: (result, error, data) => [{ type: 'NewCampaignRotationEntries', id: data.ids.join(",") }],
    }),
    createCampaignRotationEntry: builder.mutation({
      query: (data) => ({
        url: `/${data.campaign_rotation_id}/campaign_rotation_entries`,
        method: "POST",
        body: data,
        headers: postHeaders(),
      }),
      invalidatesTags: (result, error, id) => [{ type: 'CampaignRotations', id: data.campaign_rotation_id }],
    }),
    updateCampaignRotationEntry: builder.mutation({
      query: (data) => ({
        url: `/${data.campaign_rotation_id}/campaign_rotation_entries/${data.id}`,
        method: "PATCH",
        body: data,
        headers: postHeaders(),
      }),
    }),
    deleteCampaignRotationEntry: builder.mutation({
      query: (data) => ({
        url: `/${data.campaign_rotation_id}/campaign_rotation_entries/${data.id}`,
        method: "DELETE",
        body: data,
        headers: postHeaders(),
      }),
      invalidatesTags: (result, error, data) => [{ type: 'CampaignRotations', id: data.campaign_rotation_id }],
    }),
  }),
});

export const {
  useGetAvailableSizesQuery,
  useGetCampaignRotationQuery,
  useGetCampaignRotationsQuery,
  useSaveNewCampaignRotationMutation,
  useUpdateCampaignRotationMutation,
  useDeleteCampaignRotationMutation,
  useNewCampaignRotationEntriesQuery,
  useCreateCampaignRotationEntryMutation,
  useUpdateCampaignRotationEntryMutation,
  useDeleteCampaignRotationEntryMutation,
} = campaignRotationsApi;
