import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const companiesApi = createApi({
  reducerPath: 'Companies',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v2/companies' }),
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: () => '',
    }),
  })
})

export const { useGetCompaniesQuery } = companiesApi;;