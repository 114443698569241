import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger'
import { SuperadminStore } from '../api/superadmin/reducers';
import { WebsiteStore } from '../api/websites/reducers';
import { AccountImpressionsStore } from '../api/account_impressions/reducers';
import { CompanyStore } from '../api/companies/reducers';
import { ContractStore } from '../api/contracts/reducers';
import { BannerStore } from '../api/banners/reducers';
import { MetaBannerStore } from '../api/meta_banners/reducers';
import { BannerZoneStore } from '../api/banner_zones/reducers';
import { VideoZoneStore } from '../api/video_zones/reducers';
import { VideoZoneEntryStore } from '../api/video_zones/video_zone_entries/reducers';
import { BrandStore } from '../api/brands/reducers';
import { FlashMessageStore } from '../api/flash_message/reducers'
import { CampaignStore } from '../api/campaigns/reducers'
import { CompanyEventsStore } from '../api/accounts/company_events/reducers'
import { AccountStatsStore } from '../api/accounts/stats/reducers'
import { AccountPaymentStore } from '../api/accounts/payments/reducers'
import { AccountVendorGroupStore } from '../api/accounts/vendor_groups/reducers'
import { MetaCampaignsStore } from '../api/meta_campaigns/reducers';
import { BannerBundleStore } from '../api/banner_bundles/reducers';
import { BannerScheduleStore } from '../api/banner_schedules/reducers';
import multiUploaderReducer from '../slices/multi_uploader'
import websiteAnalyticsReducer from '../slices/websites'
import { BannerPublishingReducer, CampaignBannerSizesReducer, CampaignFiltersReducer, CampaignBannerRotation, CampaignsReducer } from '../slices/campaigns'
import { campaignRotationsApi } from '../api/campaign_rotations_slice'
import { companiesApi } from '../api/companies_slice'
import { brandsApi } from '../api/brands_slice'
import { campaignsApi } from '../api/campaigns_slice'
import { bannerRotationsApi } from '../api/banner_rotations_slice'
import AnalyticsReducer from '../slices/analytics'
import CampaignsSlice from '../slices/campaigns/campaigns'
// Marketplace
import { MarketplaceCampaignStore } from '../api/marketplace/campaigns/reducers'
import { MarketplaceOrderStore } from '../api/marketplace/orders/reducers'

export const store = configureStore({
  reducer: {
    AccountImpressionsStore: AccountImpressionsStore,
    AccountPaymentStore: AccountPaymentStore,
    AccountStatsStore: AccountStatsStore,
    AccountVendorGroupStore: AccountVendorGroupStore,
    BannerStore: BannerStore,
    MetaBannerStore: MetaBannerStore,
    BannerZoneStore: BannerZoneStore,
    BrandStore: BrandStore,
    CompanyStore: CompanyStore,
    ContractStore: ContractStore,
    FlashMessageStore: FlashMessageStore,
    SuperadminStore: SuperadminStore,
    CampaignStore: CampaignStore,
    WebsiteStore: WebsiteStore,
    VideoZoneStore: VideoZoneStore,
    VideoZoneEntryStore: VideoZoneEntryStore,
    CompanyEventsStore: CompanyEventsStore,
    MarketplaceCampaignStore: MarketplaceCampaignStore,
    MarketplaceOrderStore: MarketplaceOrderStore,
    MetaCampaignsStore: MetaCampaignsStore,
    BannerBundleStore: BannerBundleStore,
    MultiUploaderStore: multiUploaderReducer,
    WebsiteAnalyticsStore: websiteAnalyticsReducer,
    CampaignBannerSizesStore: CampaignBannerSizesReducer,
    CampaignFiltersStore: CampaignFiltersReducer,
    BannerPublishingStore: BannerPublishingReducer,
    BannerScheduleStore: BannerScheduleStore,
    CampaignBannerRotation: CampaignBannerRotation,
    // CampaignRotations: CampaignRotationReducer,
    Campaigns: CampaignsReducer,
    AnalyticsStore: AnalyticsReducer,
    CampaignsSlice: CampaignsSlice,

    /******** RTK slices *********/
    [campaignRotationsApi.reducerPath]: campaignRotationsApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [brandsApi.reducerPath]: brandsApi.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [bannerRotationsApi.reducerPath]: bannerRotationsApi.reducer,
    /**** End of RTK slices *****/

  },
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware();
    
    middleware = middleware.concat(campaignRotationsApi.middleware);
    middleware = middleware.concat(companiesApi.middleware);
    middleware = middleware.concat(brandsApi.middleware);
    middleware = middleware.concat(campaignsApi.middleware);
    middleware = middleware.concat(bannerRotationsApi.middleware);

    // if (process.env.NODE_ENV == "development") {
    //   middleware = middleware.concat(logger);
    // }

    return middleware;
  }
})
