import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { postHeaders } from "../../utils";
import { format, parse } from "date-fns";

const initialState = {
    isFetching: true,
    isUpdating: false,
    errors: {
        initialFetch: "",
        fetchBannerRotationInactiveEntries: "",
        addBanner: "",
        updateMetaBanner: "",
    },
    data: "",
    isEditing: false,
    totalWeightedPriority: 0,
    selectedPriorityObject: {},
    metaBannerId: '',
    tempStatus: '',
    campaignBanners: [],
    bannersToAdd: [],
    loadedInactiveEntries: false,
    pastEntriesCount: 0,
    width: 0,
    height: 0,
    rotations_created: [],
};

const errors = {
    updateBannerRotationEntry: "",
    deleteBannerRotationEntry: "",
}

const transformData = (state,payload) => {
    const { id, title, width, height, banner_rotation_entries, fallback_banner_id: fallbackBannerId, campaign_id: campaignId, past_entries_count: pastEntriesCount, show_older_schedules: showOlderSchedules} = payload;

    state.pastEntriesCount = pastEntriesCount;

    const today = new Date();

    const banners = banner_rotation_entries.map(entry => {
        const startDate = new Date(entry.starts_at_date);
        const endDate = new Date(entry.ends_at_date);
        const stoppedAt = entry.stopped_at;
        let parsedStartDate = constructDateAndTime(entry.starts_at_date, entry.starts_at_time);
        let status;
        if(parsedStartDate <= today && stoppedAt === null){
            status = "active";
        } else if(parsedStartDate > today){
            status = "pending";
        } else if (stoppedAt !== null && parsedStartDate <= today){
            status = "inactive";
        }

        const { banner_height: height, banner_width: width, ...rest} = entry;
        const transformedEntry = {...rest, height, width};
        return {...transformedEntry, isEditing: false, status, entryWasStopped: stoppedAt, errors: {...errors}};
    });

    const activeBanners = banners.filter(banner => banner.status === "active");
    const totalWeightedPriority = activeBanners.reduce((sum, el) => sum + el.priority, 0);

    return {
        ...state,
        ...(state.totalWeightedPriority = totalWeightedPriority),
        ...(state.data = {
            ...state.data,
            metaBanner: {
                id,
                title,
                height,
                width,
                fallbackBannerId,
                campaignId,
                showOlderSchedules,
            },
            bannerRotation: 
                [...activeBanners, ...banners.filter(banner => banner.status === "pending")]
            
        }),
    };
};

const constructDateAndTime = (date, time ) => {
    if (time === null) {
        return parse(date, "yyyy-MM-dd", new Date());
    }

    let concatString = date + " " + time;
    return parse(concatString, "yyyy-MM-dd HH:mm", new Date());
}

const recieveBannerEntryData = (state,payload) => { 
    const updatedBanner = payload;
    const { id, banner_width: width, banner_height: height, ...rest } = updatedBanner;
    const transformedBanner = { ...rest, height, width, id };
    const today = new Date();

    let newStatus;
    let parsedStartDate = constructDateAndTime(updatedBanner.starts_at_date, updatedBanner.starts_at_time);
    if(parsedStartDate <= today){
        newStatus = "active";
    } else if(parsedStartDate > today){
        newStatus = "pending";
    }

    const updatedBannerRotation = state.data.bannerRotation.map(banner => banner.id === id ? {...transformedBanner, status: newStatus, errors:{...errors}} : banner)

    const activeBanners = updatedBannerRotation.filter(banner => banner.status === "active");
    const totalWeightedPriority = activeBanners.reduce((sum, el) => sum + el.priority, 0);

    return {
        ...state,
        ...state.totalWeightedPriority = totalWeightedPriority,
        ...state.data.bannerRotation = updatedBannerRotation
    }
};

const transformInactiveEntryData = (state, payload) => {
    const inactiveEntries = payload;
    const today = new Date();
    const transformedEntries = inactiveEntries.map(entry => {
        const startDate = new Date(entry.starts_at_date);
        const endDate = new Date(entry.ends_at_date);

        let status;
        if(startDate <= today && endDate >= today && entry.stopped_at === null){
            status = "active";
        } else if(startDate > today && endDate >= today && entry.stopped_at === null){
            status = "pending";
        } else if(endDate < today && endDate !== null || entry.stopped_at !== null){
            status = "inactive";
        }
        const { banner_height: height, banner_width: width, ...rest} = entry;
        const transformedEntry = {...rest, height, width};
        return { ...transformedEntry, isEditing: false, status };
    });


    return {
        ...state,
        ...state.data.bannerRotation = [...state.data.bannerRotation, ...transformedEntries]
    }

}

const transformStoppedEntryData = (state, payload) => {

    const transformedEntries = state.data.bannerRotation.map(banner => {
        if(banner.id === payload.id) {
            return {...banner, entryWasStopped: true}
        } else {
            return banner;
        }
    })

    return {
        ...state,
        ...state.pastEntriesCount = state.pastEntriesCount + 1,
        ...state.data.bannerRotation = [...transformedEntries]
    }
}

const transformNewEntry = (state, payload) => {
    const { id, banner_width: width, banner_height: height, starts_at_date, starts_at_time, ...rest } = payload;

    let parsedStartDate = constructDateAndTime(starts_at_date, starts_at_time);
    const status = parsedStartDate <= new Date() ? "active" : "pending";
    const newEntry = { ...rest, starts_at_date, starts_at_time, height, width, id, status, isEditing: false};

    state.data.bannerRotation = [...state.data.bannerRotation, newEntry]
}

const recieveMetaBannerData = (state,payload) => { 
    const {id, fallback_banner_id: fallbackBannerId, ...rest} = payload;
    const updatedMetaBanner = {id, fallbackBannerId, ...rest};

    const metaBanner = state.data.metaBanner.id === id ? updatedMetaBanner : state.data.metaBanner;

    return {
        ...state,
        ...state.data.metaBanner = metaBanner
    }
 }

const setIsEditingHandler = (editState, state, id) => { 
    state.data.bannerRotation.map(banner => {
        // to do: check behaviour after entry is being added
        if (banner.id === id && banner.id !== null) banner.isEditing = editState;
    });
 }

export const fetchCampaignBanners = createAsyncThunk('fetchCampaignBanners', async ({id}, thunkAPI) => {
    const url = `/api/v2/campaigns/${id}/banners`;

    try {
        const res = await fetch(url);
        const data = await res.json();
        return data;
    } catch(err) {
        (err.response && err.response.data) || err.message || err.toString();
        return thunkAPI.rejectWithValue(message);
    }
})

export const fetchCampaignBannerRotation = createAsyncThunk('fetchCampaignBannerRotation', async ({ id }, thunkAPI) => {
    const url = `/api/v2/banner_rotations/${id}`;

    try {
        const res = await fetch(url);
        const data = await res.json();
        thunkAPI.dispatch(initializeAndTransformData(data))
        return data;
    } catch (err) {
        (err.response && err.response.data) || err.message || err.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchBannerRotationInactiveEntries = createAsyncThunk('fetchBannerRotationInactiveEntries', async ({metaBannerId}, thunkAPI) => {
    const url = `/api/v2/banner_rotations/${metaBannerId}/banner_rotation_entries?past_entries=true`

    try {
        const res = await fetch(url);
        const data = await res.json();
        thunkAPI.dispatch(initializeInactiveEntryData(data))
        return data;
    } catch(err) {
        (err.response && err.response.data) || err.message || err.toString();
        return thunkAPI.rejectWithValue(message);
    }
})

export const addBanner = createAsyncThunk('addBanner', async ({metaBannerId, data}, thunkAPI) => {
    try {
        const res = await fetch(`/api/v2/banner_rotations/${metaBannerId}/banner_rotation_entries`, {
            method: 'POST',
            headers: postHeaders(),
            body: JSON.stringify(data),
        });
        const bannerData = await res.json();
        thunkAPI.dispatch(initializeAndTransformNewEntry(bannerData));
        return bannerData;
    } catch(err) {
        new thunkAPI.rejectWithValue(err);
    }
})

export const updateBannerRotationEntry = createAsyncThunk(
    "updateBannerRotationEntry",
    async ({ metaBannerId, id, data }, thunkAPI) => {
      try {
        const res = await fetch(
          `/api/v2/banner_rotations/${metaBannerId}/banner_rotation_entries/${id}`,
          {
            method: "PATCH",
            headers: postHeaders(),
            body: JSON.stringify(data),
          }
        );
        const updatedData = await res.json();
        thunkAPI.dispatch(initializeRecieveData(updatedData));
        return updatedData;
      } catch (err) {
        return thunkAPI.rejectWithValue({error: err.message, id});
      }
    }
  );

  export const createBannerRotation = createAsyncThunk(
    "createBannerRotation",
    async ({campaign_id, data }, thunkAPI) => {
        try {
            const res = await fetch(`/api/v2/campaigns/${campaign_id}/banner_rotations`, {
                headers: postHeaders(),
                method: "POST",
                body: JSON.stringify(data),
            })
            const createdData = await res.json();
            return createdData;
        } catch (err) {
            new thunkAPI.rejectWithValue(err);
        }
    }
  );

  export const createMultipleBannerRotatoins = createAsyncThunk(
    "createMultipleBannerRotations",
    async ({campaign_id, data }, thunkAPI) => {
        try {
            const res = await fetch(`/api/v2/campaigns/${campaign_id}/banner_rotations/bulk`, {
                headers: postHeaders(),
                method: "POST",
                body: JSON.stringify(data),
            })
            const createdData = await res.json();
            return createdData;
        } catch (err) {
            new thunkAPI.rejectWithValue(err);
        }
    }
  )

export const updateMetaBanner = createAsyncThunk('updateMetaBanner', async ({ id, data }, thunkAPI) => {
    try {
        const res = await fetch(`/api/v2/banner_rotations/${id}`, {
            method: 'PATCH',
            headers: postHeaders(),
            body: JSON.stringify(data),
        });
        const updatedBanner = await res.json();
        thunkAPI.dispatch(initializeRecieveMetaBannerData(updatedBanner))
        return updatedBanner;
    } catch (err) {
        new thunkAPI.rejectWithValue(err);
    }
});

export const deleteBannerRotationEntry = createAsyncThunk('deleteBannerRotationEntry', async ({metaBannerId, id, actionType, banner}, thunkAPI) => {
    try {
        const res = await fetch(`/api/v2/banner_rotations/${metaBannerId}/banner_rotation_entries/${id}`, {
            method: 'DELETE',
            headers: postHeaders(),
        })
        const data = await res.json();
        if(actionType === 'deleteEntry') {
            thunkAPI.dispatch(deleteBannerEntry({id}));
        } else {
            thunkAPI.dispatch(stopBannerEntry(banner));
        }
        return data;
    } catch(err) {
        thunkAPI.dispatch(setDeleteStopErrorMessage({id, actionType}));
            
        return thunkAPI.rejectWithValue({error: err.message, id});
    }
})


const campaignBannerRotationSlice = createSlice({
    name: "campaignBannerRotation",
    initialState,
    reducers: {
        setMetaBannerId(state, action) {
            state.metaBannerId = action.payload;
        },
        setIsEditing(state, action) {
            const { id } = action.payload;
            setIsEditingHandler(true, state, id);
        },
        setIsNotEditing(state, action) {
            const { id } = action.payload;
            setIsEditingHandler(false, state, id);
        },
        setSelectedPriorityObject(state, action) {
            const { id, value } = action.payload;
            state.selectedPriorityObject[id] = value;
            state.totalWeightedPriority = Object.values(state.selectedPriorityObject).reduce((sum, el) => sum + el, 0);
        },
        clearSelectedPriorityObject(state) {
            state.selectedPriorityObject = {};
        },
        initializeAndTransformData(state, action) {
            transformData(state, action.payload);
        },
        initializeRecieveData(state,action) {
            recieveBannerEntryData(state, action.payload)
        },
        initializeRecieveMetaBannerData(state,action) {
            recieveMetaBannerData(state, action.payload)
        },
        initializeInactiveEntryData(state, action) {
            transformInactiveEntryData(state, action.payload)
        },
        initializeAndTransformNewEntry(state, action){
            transformNewEntry(state, action.payload)
        },
        setTempEntryStatus(state,action) {
            state.tempStatus = action.payload
        },
        setBannersToAdd(state, action){
            const bannersToAddIds = action.payload;

            state.bannersToAdd = state.campaignBanners
                .filter(banner => bannersToAddIds.includes(banner.id))
                .map(banner => {
                return {
                    active: false,
                    height: banner.height,
                    banner_id: banner.id,
                    banner_preview_link: banner.banner_preview_link,
                    banner_preview_path: banner.banner_preview_path,
                    banner_title: banner.title,
                    width: banner.width,
                    timeslot_end: null,
                    // id will be replaced with the response from the backend
                    id: null,
                    isEditing: true,
                    priority: 5,
                    starts_at_date: format(new Date(), "yyyy-MM-dd"),
                    starts_at_time: null,
                    ends_at_date: null,
                    ends_at_time: null,
                    timeslot_start: null,
                    status: "active",
                    weekdays: {
                        Monday: true,
                        Tuesday: true,
                        Wednesday: true,
                        Thursday: true,
                        Friday: true,
                        Saturday: true,
                        Sunday: true,
                    },
                    isTemp: true,
                };
            })

            state.data.bannerRotation = [...state.bannersToAdd, ...state.data.bannerRotation];
        },
        removeBannerToAdd(state, action){
            const indexToRemove = action.payload;
            state.bannersToAdd.splice(indexToRemove, 1);
            state.data.bannerRotation.splice(indexToRemove, 1);
            
        },
        stopBannerEntry(state, action) {
            transformStoppedEntryData(state, action.payload);
        },
        deleteBannerEntry(state, action) {
            const {id} = action.payload;
            state.data.bannerRotation = state.data.bannerRotation.filter(banner => banner.id !== id);
        },
        setDeleteStopErrorMessage(state, action) {
            const {id: entryId, actionType: type} = action.payload;

            if(type === 'deleteEntry'){
                state.data.bannerRotation.map(banner => {
                    banner.id === entryId ? banner.errors.deleteBannerRotationEntry = "There was an error deleting the entry" : null;
                })
            } else {
                state.data.bannerRotation.map(banner => {
                    banner.id === entryId ? banner.errors.deleteBannerRotationEntry = "There was an error stopping the entry" : null;
                })
            }
        },
        resetErrorState(state, action){
            const errorType = action.payload;

            if(state.errors[errorType]){
                state.errors[errorType] = "";
            }
        },
        resetEntryErrors(state, action){
            const {errorType, entryId} = action.payload
            
            state.data.bannerRotation.map(banner => {
                if(banner.id === entryId){
                    banner.errors[errorType] = ''
                }
            })

        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCampaignBannerRotation.pending, (state, action) => {
                state.isEditing = false;
                state.isFetching = true;
            })
            .addCase(fetchCampaignBannerRotation.fulfilled, (state, action) => {
                state.isFetching = false;
                if(state.campaignBanners.length){
                    state.errors.initialFetch = "";
                }
            })
            .addCase(fetchCampaignBannerRotation.rejected, (state, action) => {
                state.isFetching = false;    
                state.errors.initialFetch = "There was an error fetching banner rotation data. Please try to refresh the page.";
            })
            .addCase(updateBannerRotationEntry.pending, (state, action) => {
                state.isUpdating = true;
            })
            .addCase(updateBannerRotationEntry.fulfilled, (state, action) => {
                const {id: entryId} = action.payload;
                state.isUpdating = false;
                state.isEditing = false;
                state.tempBannerStatus = "";
                state.data.bannerRotation.map(banner => {
                    if(banner.id === entryId) banner.errors.updateBannerRotationEntry = ''
                })
            })
            .addCase(updateBannerRotationEntry.rejected, (state, action) => {
                state.isUpdating = false;
                const {id: entryId} = action.payload;
                state.data.bannerRotation.map(banner => {
                    if(banner.id === entryId){
                        banner.errors.updateBannerRotationEntry = 'There was an error updating banner rotation entry. Please try again.';
                    }
                })
            })
            .addCase(updateMetaBanner.pending, (state, action) => {
                state.isUpdating = true;
            })
            .addCase(updateMetaBanner.fulfilled, (state, action) => {
                state.isUpdating = false;
                state.data.metaBanner.title = action.payload.title;
                state.errors.updateMetaBanner = '';
            })
            .addCase(updateMetaBanner.rejected, (state, action) => {
                state.isUpdating = false;
                state.errors.updateMetaBanner = 'There was an error updating meta banner. Please try again.';
            })
            .addCase(fetchCampaignBanners.pending, (state, action) => {
                state.isFetching = true;
            })
            .addCase(fetchCampaignBanners.fulfilled, (state, action) => {
                state.isFetching = false;
                if(state.campaignBanners.length && state.bannerRotation.metaBanner.length){
                    state.errors.initialFetch = '';
                }

                state.campaignBanners = action.payload
                    .filter(
                        banner =>
                            banner.width === state.data.metaBanner.width &&
                            banner.height === state.data.metaBanner.height
                    )
                    .map(banner => ({
                        ...banner,
                        banner_preview_link: `/banners/${banner.id}/`,
                        banner_preview_path: `/banners/${banner.id}/preview`,
                    }));
            })
            .addCase(fetchCampaignBanners.rejected, (state, action) => {
                state.isFetching = false;
                state.errors.initialFetch = 'There was an error fetching fallback banners. Please try to refresh the page.';
            })
            .addCase(fetchBannerRotationInactiveEntries.pending, (state, action) => {
            })
            .addCase(fetchBannerRotationInactiveEntries.fulfilled, (state, action) => {
                state.loadedInactiveEntries = true;
                state.errors.fetchBannerRotationInactiveEntries = '';

            })
            .addCase(fetchBannerRotationInactiveEntries.rejected, (state, action) => {
                state.errors.fetchBannerRotationInactiveEntries = 'There was an error fetching inactive banner rotation entries. Please try again.';
            })
            .addCase(deleteBannerRotationEntry.pending, (state, action) => {
                state.isUpdating = true;
            })
            .addCase(deleteBannerRotationEntry.fulfilled, (state, action) => {
                state.isUpdating = false;
                state.errors.deleteBannerRotationEntry = '';
                state.data.bannerRotation = state.data.bannerRotation.filter(entry => entry.banner_id !== action.payload.banner_id);

                state.data.bannerRotation.map(banner => {
                    if(banner.id === action.payload.id){
                        banner.errors.deleteBannerRotationEntry = '';
                    }
                }
                )
            })
            .addCase(deleteBannerRotationEntry.rejected, (state, action) => {
                state.isUpdating = false;

            })
            .addCase(addBanner.pending, (state, action) => {
                state.isUpdating = true;
            })
            .addCase(addBanner.fulfilled, (state, action) => {
                state.isUpdating = false;
                initializeAndTransformNewEntry(state, action.payload);
                state.errors.addBanner = ''; 
            })
            .addCase(addBanner.rejected, (state, action) => {
                state.isUpdating = false;
                state.errors.addBanner = "There was an error adding a new banner. Please try again.";
            })
            .addCase(createBannerRotation.fulfilled, (state, action) => {
                state.id = action.payload.id;
            })
            .addCase(createBannerRotation.rejected, (state, action) => {
                state.error = "There was an error creating a new banner rotation";
            })
            .addCase(createMultipleBannerRotatoins.fulfilled, (state, action) => {
                state.rotations_created = action.payload;
            })
        },
});

export const { setIsEditing, setIsNotEditing, setSelectedPriorityObject, setMetaBannerId, initializeRecieveData, initializeAndTransformData, setTempEntryStatus, initializeRecieveMetaBannerData, initializeInactiveEntryData, clearSelectedPriorityObject, setBannersToAdd, removeBannerToAdd, stopBannerEntry, deleteBannerEntry, resetErrorState, resetEntryErrors, setDeleteStopErrorMessage, initializeAndTransformNewEntry } =
    campaignBannerRotationSlice.actions;
const { reducer } = campaignBannerRotationSlice;
export default reducer;